<template lang="pug">
article.recovery
  .recovery__cont.cont
    h1.recovery__caption.caption Возврат
    p.recovery__text-main.text-big По любым вопросам возврата товара обратитесь по:
    p.recovery__text-main.recovery__text-main_point.text-big
      | телефону
      |
      a.recovery__link.text-big(:href="phone.link") {{phone.mask}}
      |
      | каждый день с 9:00 до 19:00 по московскому времени
    p.recovery__text-main.recovery__text-main_point.text-big
      | электронной почте
      |
      a.recovery__link.text-big(:href="mail.link") {{mail.mask}}
    h2.recovery__title.title Порядок возврата товара
    p.recovery__text.text-big Возврат товара может быть произведен в случае обнаружения ненадлежащего качества товара.
    p.recovery__text.text-big Продавец несет ответственность за недостатки товара, если они возникли по его вине (производственные дефекты), либо возникли до момента поставки товара заказчику.
    p.recovery__text.text-big В соответствии с п. 2 ст. 475 Гражданского кодекса РФ, требование о расторжении договора и возврате уплаченной за товар денежной суммы может быть предъявлено в случае существенного нарушения требований к качеству товара (обнаружения неустранимых недостатков, недостатков, которые не могут быть устранены без несоразмерных расходов или затрат времени, или выявляются неоднократно, либо проявляются вновь после их устранения, и других подобных недостатков).
    p.recovery__text.text-big При обнаружении устранимых недостатков товара производственного характера, либо иных недостатков, которые возникли до момента поставки товара, в соответствии с п. 1 ст. 475 ГК РФ, возможны следующие варианты:
    p.recovery__text.recovery__text_point.text-big соразмерное уменьшение покупной цены товара;
    p.recovery__text.recovery__text_point.text-big безвозмездное устранение недостатков товара в разумный срок;
    p.recovery__text.recovery__text_point.text-big возмещение Ваших расходов на устранение недостатков товара.
    p.recovery__text.text-big В случае приобретения товара физическим лицом исключительно для личных, семейных, домашних и иных нужд, не связанных с осуществлением предпринимательской деятельности, при обнаружении ненадлежащего качества товара, покупатель вправе по своему выбору заявлять требования, установленные ст. 18 Закона РФ «О защите прав потребителей».
    p.recovery__text.text-big Если товар качественный и приобретен физическим лицом исключительно для личных, семейных, домашних и иных нужд, не связанных с осуществлением предпринимательской деятельности, в течение 14 дней с даты покупки покупатель может требовать замены товара на аналогичный, если он не подошел по форме, габаритам, фасону, расцветке, размеру или комплектации (вариант, что просто не нравится и другие, не перечисленные здесь причины не являются основанием). Обмен товара проводится, если указанный товар не был в употреблении, сохранены его товарный вид, потребительские свойства, пломбы, фабричные ярлыки.
    p.recovery__text.text-big Возврат товара производится, если аналогичный товар (подходящий по форме, габаритам, фасону, расцветке, размеру или комплектации) отсутствует в продаже на день обращения потребителя к продавцу.
    p.recovery__text.text-big Обращаем внимание, что требование о возврате товара надлежащего качества не может быть предъявлено в отношении мебели, посуды и принадлежностей столовых и кухонных товаров, а также иных товаров, в соответствии с Перечнем непродовольственных товаров надлежащего качества, не подлежащих возврату или обмену на аналогичный товар других размера, формы, габарита, фасона, расцветки или комплектации, утвержденным Постановлением Правительства РФ от 19.01.98 № 55.
</template>

<script>
import '@/assets/styles/components/recovery.sass'
import { mail, phone } from '../assets/scripts/variables'

export default {
  name: 'Recovery',
  data () {
    return {
      mail,
      phone
    }
  }
}
</script>
