<template lang="pug">
head-meta(title-page="Возврат мебели в интернет магазин ChiedoHome", description="Порядок возврата товара в интернет магазин ChiedoHome")
recovery
</template>

<script>
import Recovery from '../components/Recovery'
import HeadMeta from '../components/_layout/HeadMeta'

export default {
  name: 'RecoveryPage',
  components: { HeadMeta, Recovery }
}
</script>
